import { styled } from '@mui/material';

export const Container = styled('div')(({ theme: { breakpoints } }) => ({
  position: 'fixed',
  top: 0,
  width: '100%',
  background: '#000',
  display: 'flex',
  flexDirection: 'column',
  zIndex: 10002,
}));
