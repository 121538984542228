import React, { FC, ReactNode, createContext, useContext } from 'react';

const LocaleContext = createContext<{
  locale: string;
} | null>(null);

export const LocaleProvider: FC<{
  children: ReactNode;
  locale: string;
}> = ({ children, locale }) => {
  return (
    <LocaleContext.Provider value={{ locale }}>
      {children}
    </LocaleContext.Provider>
  );
};

export function useLocale() {
  const context = useContext(LocaleContext);
  if (!context) {
    throw new Error('Missing Locale context');
  }
  return context.locale;
}
