import React, { FC, useEffect, useState } from 'react';
import { Theme, useMediaQuery } from '@mui/material';

import * as routes from '@/config/routes';
import logo from '@/images/logo.png';
import gameDashboardArrow from '@/images/game-dashboard-arrow.svg';
import downArrowWhite from '@/images/down-arrow-white.svg';
import { useTracker } from '@/contexts/analytics';

import * as S from './Header.styled';
import { Menui18N } from '../HeaderTopbar';

interface IHeaderProps {
  i18n: Menui18N;
}

export const Header: FC<IHeaderProps> = ({ i18n }) => {
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
  const [showMenu, setShowMenu] = useState(false);
  const { tracker } = useTracker();
  const [showMediumMenu, setShowMediumMenu] = useState<boolean>(false);

  useEffect(() => {
    setShowMenu(isDesktop);
  }, [isDesktop, setShowMenu]);

  const onToggleMenu = () => {
    setShowMenu((showMenu) => !showMenu);
  };

  return (
    <>
      <S.Container mobileMenu={!isDesktop && showMenu}>
        <S.HeaderInner>
          <S.ImageLink
            to={routes.ROOT}
            onClick={() => {
              tracker.page({ page: 'homepage' });
            }}
          >
            <S.Logo src={logo} alt='Aurory Logo' />
          </S.ImageLink>

          <S.MenuToggler
            onClick={onToggleMenu}
            className={showMenu ? 'open' : ''}
          >
            <span></span>
            <span></span>
            <span></span>
          </S.MenuToggler>

          <S.Menu>
            {!isDesktop && showMenu && (
              <S.PlayNowBtnWrapper
                onClick={(e) => {
                  tracker.page({ page: 'play-now-btn' });
                  window.open(routes.APP);
                }}
              >
                <S.PlayNowBtn>{i18n.playNow} </S.PlayNowBtn>
              </S.PlayNowBtnWrapper>
            )}
            <S.MenuItem
              data-testid='marketplace'
              onClick={(e) => {
                tracker.page({ page: 'marketplace' });
              }}
            >
              <S.ExternalLink href={routes.MARKETPLACE}>
                {i18n.marketplace}
              </S.ExternalLink>
            </S.MenuItem>
            <S.MediumDropdownMenuItem>
              <S.MediumDropdownMenuTitle
                onClick={() => setShowMediumMenu(!showMediumMenu)}
              >
                {i18n.more}{' '}
                <S.MoreArrowImage src={downArrowWhite} alt='more menu' />
              </S.MediumDropdownMenuTitle>
              {showMediumMenu && (
                <S.MediumDropdownMenuItems>
                  <S.MenuItem data-testid='patch-notes'>
                    <S.Link
                      href={routes.PATCH_NOTES}
                      onClick={() => {
                        tracker.page({ page: 'patch-notes' });
                        !isDesktop && setShowMenu(false);
                      }}
                    >
                      {i18n.news}
                    </S.Link>
                  </S.MenuItem>
                  <S.MenuItem data-testid='press-releases'>
                    <S.Link
                      to={routes.PRESS_RELEASES}
                      onClick={() => {
                        tracker.page({ page: 'press-releases' });
                        !isDesktop && setShowMenu(false);
                      }}
                    >
                      {i18n.pressReleases}
                    </S.Link>
                  </S.MenuItem>
                  <S.MenuItem data-testid='faq'>
                    <S.ExternalLink
                      href={routes.FAQ}
                      onClick={() => {
                        tracker.page({ page: 'faq' });
                        setShowMenu(false);
                      }}
                    >
                      {i18n.faq}
                    </S.ExternalLink>
                  </S.MenuItem>
                  <S.MenuItem data-testid='about-us'>
                    <S.Link
                      to={routes.ABOUT_US}
                      onClick={() => {
                        tracker.page({ page: 'about-us' });
                        setShowMenu(false);
                      }}
                    >
                      {i18n.aboutUs}
                    </S.Link>
                  </S.MenuItem>
                  <S.MenuItem data-testid='whitepaper'>
                    <S.ExternalLink
                      href={routes.WHITEPAPER}
                      onClick={() => {
                        tracker.page({ page: 'whitepaper' });
                        setShowMenu(false);
                      }}
                    >
                      {i18n.whitePaper}
                    </S.ExternalLink>
                  </S.MenuItem>
                </S.MediumDropdownMenuItems>
              )}
            </S.MediumDropdownMenuItem>
            <S.DesktopMenuItems>
              <S.MenuItem data-testid='patch-notes'>
                <S.Link
                  to={routes.PATCH_NOTES}
                  onClick={() => {
                    tracker.page({ page: 'patch-notes' });
                    !isDesktop && setShowMenu(false);
                  }}
                >
                  {i18n.news}
                </S.Link>
              </S.MenuItem>
              <S.MenuItem data-testid='press-releases'>
                <S.Link
                  to={routes.PRESS_RELEASES}
                  onClick={() => {
                    tracker.page({ page: 'press-releases' });
                    !isDesktop && setShowMenu(false);
                  }}
                >
                  {i18n.pressReleases}
                </S.Link>
              </S.MenuItem>
              <S.MenuItem data-testid='faq'>
                <S.ExternalLink
                  href={routes.FAQ}
                  onClick={() => {
                    tracker.page({ page: 'faq' });
                    !isDesktop && setShowMenu(false);
                  }}
                >
                  {i18n.faq}
                </S.ExternalLink>
              </S.MenuItem>
              <S.MenuItem data-testid='whitepaper'>
                <S.ExternalLink
                  href={routes.WHITEPAPER}
                  onClick={() => {
                    tracker.page({ page: 'whitepaper' });
                    setShowMenu(false);
                  }}
                >
                  {i18n.whitePaper}
                </S.ExternalLink>
              </S.MenuItem>
              <S.MenuItem data-testid='about-us'>
                <S.Link
                  to={routes.ABOUT_US}
                  onClick={() => {
                    tracker.page({ page: 'about-us' });
                    !isDesktop && setShowMenu(false);
                  }}
                >
                  {i18n.aboutUs}
                </S.Link>
              </S.MenuItem>
            </S.DesktopMenuItems>

            <S.DashboardButton href={routes.APP}>
              {i18n.gameDashboard}{' '}
              <S.GameDashboardImage
                src={gameDashboardArrow}
                alt='navigate to game dashboard'
              />
            </S.DashboardButton>
          </S.Menu>
        </S.HeaderInner>
      </S.Container>
    </>
  );
};

export default Header;
