import React from 'react';

import {
  Link as GatsbyLink,
  // GatsbyLinkProps
} from 'gatsby';

// import { useLocale } from '@/contexts/locale';

type GatsbyLinkProps = any;

export default function Link({ to, ...props }: GatsbyLinkProps) {
  // const locale = useLocale();
  // return <GatsbyLink to={`/${locale}${to}`} {...props} />;
  return <GatsbyLink to={`${to}`} {...props} />;
}
