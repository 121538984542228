import React, { ReactNode } from 'react';
import {
  MenuItem as MenuItemUnstyled,
  menuItemClasses as menuItemUnstyledClasses,
  MenuItemProps as MenuItemUnstyledProps,
  Popper as PopperUnstyled,
  styled,
  css,
} from '@mui/material';

import BaseLink from '@/components/shared/LocaleLink';
import mobileHeaderPlayNowBg from '@/images/header-play-now-bg.png';
import { StyledOutboundLink } from '@/styles/container';

export const Container = styled(
  ({
    children,
    mobileMenu,
    ...props
  }: {
    mobileMenu: boolean;
    children: ReactNode;
  }) => <div {...props}>{children}</div>
)(({ theme: { breakpoints }, mobileMenu }) => ({
  height: mobileMenu ? 'calc(100vh - 1.5rem)' : `4.75rem`,
  display: 'flex',
  background: '#000',
  backdropFilter: 'blur(50px)',
  zIndex: 20,
  userSelect: 'none',
  transition: 'height .2s ease',
  [`${breakpoints.up('xs')} and (orientation: landscape)`]: {
    height: mobileMenu ? 'calc(100vh - 1.5rem)' : `3.65rem`,
  },
  [`${breakpoints.up('md')}`]: {
    height: mobileMenu ? 'calc(100vh - 1.5rem)' : `4.75rem`,
  },
}));

export const Logo = styled('img')(({ theme: { breakpoints } }) => ({
  height: '3rem',
  padding: '0.5rem 0.5rem 0.3rem 0.5rem',
  marginLeft: '1rem',
  marginRight: '3rem',
  cursor: 'pointer',
  flexShrink: '0',
  gridArea: `'logo'`,

  [breakpoints.down('md')]: {
    margin: 0,
  },
}));

export const MenuToggler = styled('div')(({ theme: { breakpoints } }) => ({
  gridArea: 'toggler',
  width: '2.5rem',
  height: '1rem',
  position: 'relative',
  margin: '3.125rem auto',
  WebkitTransform: 'rotate(0deg)',
  MozTransform: 'rotate(0deg)',
  OTransform: 'rotate(0deg)',
  transform: 'rotate(0deg)',
  WebkitTransition: '0.5s ease-in-out',
  MozTransition: '0.5s ease-in-out',
  OTransition: '0.5s ease-in-out',
  transition: '0.5s ease-in-out',
  cursor: 'pointer',
  zIndex: 100,

  [breakpoints.down('md')]: {
    margin: '0',
    width: '2.625rem',
  },

  span: {
    display: 'block',
    position: 'absolute',
    height: '0.1875rem',
    width: '100%',
    background: '#fff',
    borderRadius: '0.1875rem',
    opacity: '1',
    left: '0',
    WebkitTransform: 'rotate(0deg)',
    MozTransform: 'rotate(0deg)',
    OTransform: 'rotate(0deg)',
    transform: 'rotate(0deg)',
    WebkitTransition: '0.25s ease-in-out',
    MozTransition: '0.25s ease-in-out',
    OTransition: '0.25s ease-in-out',
    transition: '0.25s ease-in-out',

    '&:first-of-type': {
      top: '0',
      WebkitTransformOrigin: 'left center',
      MozTransformOrigin: 'left center',
      OTransformOrigin: 'left center',
      transformOrigin: 'left center',
    },
    '&:nth-of-type(2)': {
      top: '0.625rem',
      WebkitTransformOrigin: 'left center',
      MozTransformOrigin: 'left center',
      OTransformOrigin: 'left center',
      transformOrigin: 'left center',
    },
    '&:nth-of-type(3)': {
      top: '1.25rem',
      WebkitTransformOrigin: 'left center',
      MozTransformOrigin: 'left center',
      OTransformOrigin: 'left center',
      transformOrigin: 'left center',
    },
  },
  '&.open': {
    span: {
      '&:first-of-type': {
        WebkitTransform: 'rotate(45deg)',
        MozTransform: 'rotate(45deg)',
        OTransform: 'rotate(45deg)',
        transform: 'rotate(45deg)',
        top: '-0.1875rem',
        left: '0.5rem',
      },
      '&:nth-of-type(2)': {
        width: '0%',
        opacity: '0',
      },
      '&:nth-of-type(3)': {
        WebkitTransform: 'rotate(-45deg)',
        MozTransform: 'rotate(-45deg)',
        OTransform: 'rotate(-45deg)',
        transform: 'rotate(-45deg)',
        top: '1.625rem',
        left: '0.5rem',
      },
    },
  },
  display: 'none',

  [breakpoints.down('md')]: {
    display: 'block',
    position: 'absolute',
    top: '1.2rem',
    right: '1rem',
    margin: '0',
  },
}));

const link = {
  marginLeft: '1rem',
  marginRight: '1rem',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '0.5rem',
  borderRadius: '0.5rem',

  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.15)',
  },

  '&.active': {
    borderBottom: '0.3125rem solid #00e9c9',
    padding: '1.75rem 0 1.375rem',
  },
};

export const Link = styled(BaseLink)(({ theme: { breakpoints } }) => ({
  ...link,
  [breakpoints.down('md')]: {
    width: '100%',
    textAlign: 'left',
    justifyContent: 'flex-start',
    padding: '1rem',
  },
}));

export const ImageLink = styled(BaseLink)(({ theme: { breakpoints } }) => ({
  [breakpoints.down('md')]: {},
}));

export const ExternalLink = styled(StyledOutboundLink)(
  ({ theme: { breakpoints } }) => ({
    ...link,
    [breakpoints.down('md')]: {
      width: '100%',
      textAlign: 'left',
      justifyContent: 'flex-start',
      padding: '1rem',
    },
  })
);

export const MobileMenuItem = styled('div')(({ theme: { breakpoints } }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',

  borderTop: '0.0625rem solid rgba(255, 255, 255, 0.3)',
  borderBottom: '0.0625rem solid rgba(255, 255, 255, 0.3)',
  paddingTop: '0.375rem',
  paddingBottom: '0.375rem',

  fontStyle: 'normal',
  fontFamily: 'Montserrat',
  fontSize: '0.75rem',
  color: '#ffffff',
  cursor: 'pointer',
  width: '100%',

  [breakpoints.down('md')]: {
    marginLeft: '0',
  },
}));

export const SearchInput = styled('input')({
  background: '#f9f9f9',
  border: 'none',
  borderRadius: '1.25rem 0 0 1.25rem',
  height: '2.375rem',
  width: '14.75rem',
  outline: 'none',
  paddingLeft: '1.25rem',
  color: '#636363',
});

const MENU_HEIGHT = 'calc(100% - 5rem)';

export const Menu = styled('div')(({ theme: { breakpoints } }) => ({
  gridArea: 'menu',
  display: 'flex',

  [breakpoints.down('md')]: {
    flexDirection: 'column',
    overflowY: 'auto',
    maxHeight: MENU_HEIGHT,
    height: MENU_HEIGHT,
    flex: '1',
    background: '#000',
    paddingBottom: '1rem',
  },

  [breakpoints.up('md')]: {
    alignItems: 'center',
  },
}));

const menuItem = css({
  fontStyle: 'normal',
  fontWeight: '700',
  fontSize: '1rem',
  lineHeight: '1.1875rem',
  display: 'flex',
  alignItems: 'center',
  textAlign: 'center',
  color: '#ffffff',
  cursor: 'pointer',
});

export const MenuLink = styled(Link)({
  ...menuItem,
});

export const MenuItem = styled('div')(({ theme: { breakpoints } }) => ({
  fontStyle: 'normal',
  fontWeight: '400',
  fontSize: '1rem',
  lineHeight: '1.1875rem',
  display: 'flex',
  whiteSpace: 'nowrap',
  alignItems: 'center',
  textAlign: 'center',
  color: '#ffffff',
  cursor: 'pointer',
  textShadow: '0rem 0.25rem 0.25rem rgba(0, 0, 0, 0.25)',

  [breakpoints.down('md')]: {
    marginTop: '0.5rem',
    marginBottom: '0.5rem',
    textAlign: 'left',
    width: '100%',
    fontSize: '1.25rem',
    lineHeight: '1.5rem',
    height: '2.875rem',
  },
}));

export const StyledMenuItem = styled(
  ({
    colored,
    className,
    ...props
  }: MenuItemUnstyledProps & { colored: boolean }) => (
    <MenuItemUnstyled {...{ className }} {...props} />
  )
)(({ colored }) => ({
  listStyle: 'none',
  padding: '0.25rem',
  paddingTop: '0.375rem',
  paddingBottom: '0.375rem',
  paddingLeft: '0.75rem',
  textShadow: '0rem 0.25rem 0.25rem rgba(0, 0, 0, 0.25)',
  background: 'black',
  borderTop: '0.0625rem solid  rgba(255, 255, 255, 0.3)',
  borderBottom: '0.0625rem solid  rgba(255, 255, 255, 0.3)',
  color: colored ? '#00e9c9' : '#ffffff',
  cursor: 'pointer',
  fontFamily: "'Montserrat'",
  fontStyle: 'normal',
  fontWeight: '700',
  fontSize: '0.75rem',

  [`& .${menuItemUnstyledClasses.root}`]: {
    fontFamily: "'Montserrat'",
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '0.75rem',
  },
  [`& .${menuItemUnstyledClasses.root}:last-of-type`]: {
    borderBottom: 'none',
  },
  [`& .${menuItemUnstyledClasses.focusVisible}`]: {
    outline: '0.1875rem solid white',
    backgroundColor: 'black',
    color: 'white',
  },
  [`& .${menuItemUnstyledClasses.disabled}`]: {
    color: 'white',
  },
  [`& .${menuItemUnstyledClasses.root}:hover:not(.${menuItemUnstyledClasses.disabled})`]:
    {
      backgroundColor: 'rgba(0, 233, 201, 0.5)',
      color: 'white',
    },
}));

export const Popper = styled(PopperUnstyled)({
  zIndex: '1',
});

export const TournamentButton = styled(StyledOutboundLink)(
  ({ theme: { breakpoints } }) => ({
    width: '15.3125rem',
    height: '2.25rem',
    color: '#000',
    fontSize: '1rem',
    fontWeight: '700',
    borderRadius: '2.0625rem',
    whiteSpace: 'nowrap',
    background: '#FFD600',
    padding: '0.3rem 1rem',
    marginLeft: '1rem',
    marginRight: '1rem',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    [breakpoints.down('md')]: {
      marginBottom: '1rem',
    },
  })
);

export const Divider = styled('div')({
  display: 'flex',
  flex: 1,
});

export const DashboardButton = styled(StyledOutboundLink)(
  ({ theme: { breakpoints } }) => ({
    background:
      'linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), linear-gradient(80.38deg, #00E9C9 0%, #48A6D0 100%)',
    borderRadius: '0.5rem',
    width: '13.75rem',
    height: '2.75rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: '0.5rem',
    '&:hover': {
      background:
        'linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),linear-gradient(80.38deg, #31ffe3 0%, #00E9C9 100%)',
    },
    [breakpoints.down('md')]: {
      marginTop: '0',
      height: '3.5rem',
      minHeight: '3.5rem',
      marginLeft: '1rem',
      marginRight: '1rem',
      textTransform: 'uppercase',
      fontWeight: '600',
      fontSize: '1.25rem',
      width: 'unset',
    },
  })
);

export const GameDashboardImage = styled('img')({
  marginLeft: '0.5rem',
});

export const MediumDropdownMenuItem = styled('div')(
  ({ theme: { breakpoints } }) => ({
    cursor: 'pointer',
    position: 'relative',
    display: 'none',
    [breakpoints.down('lg')]: {
      display: 'flex',
      marginRight: '1rem',
    },
    [breakpoints.down('md')]: {
      display: 'none',
    },
  })
);

export const DesktopMenuItems = styled('div')(({ theme: { breakpoints } }) => ({
  display: 'flex',
  [breakpoints.down('lg')]: {
    display: 'none',
  },
  [breakpoints.down('md')]: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
}));

export const MoreArrowImage = styled('img')({
  marginLeft: '0.5rem',
});

export const MediumDropdownMenuTitle = styled('div')(
  ({ theme: { breakpoints } }) => ({
    padding: '0.5rem',
    borderRadius: '0.5rem',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.15)',
    },
  })
);

export const MediumDropdownMenuItems = styled('div')({
  position: 'absolute',
  width: '10.75rem',
  padding: '0.5rem',
  background: '#000',
  backdropFilter: 'blur(50px)',
  borderRadius: '0.5rem',
  top: '4rem',
});

export const HeaderInner = styled('div')(({ theme: { breakpoints } }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  maxHeight: '4.75rem',

  [breakpoints.down('md')]: {
    flexDirection: 'column',
    maxHeight: 'unset',
    justifyContent: 'unset',
    alignItems: 'unset',
    flex: 1,
  },
}));

export const PlayNowBtnWrapper = styled('div')(
  ({ theme: { breakpoints } }) => ({
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  })
);

export const PlayNowBtn = styled('div')(({ theme: { breakpoints } }) => ({
  background: `url(${mobileHeaderPlayNowBg}) no-repeat`,
  backgroundSize: '100% 100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontWeight: 700,
  fontSize: '2.25rem',
  minHeight: '10rem',
  width: 'auto',
  minWidth: '21.875rem',

  [breakpoints.up('md')]: {
    minHeight: '10rem',
    height: 'auto',
  },
}));
