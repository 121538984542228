import React, { HTMLProps } from 'react';
import { styled } from '@mui/material/styles';
import { OutboundLink, OutboundLinkProps } from 'gatsby-plugin-google-gtag';

export const Page = styled('div')({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
});

export const Bg = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: '1',
});

export const Body = styled('div')(({ theme: { breakpoints } }) => ({
  display: 'flex',
  flexDirection: 'column',
  paddingTop: `8.125rem`,

  [breakpoints.down('lg')]: {
    paddingTop: '10.125rem',
  },

  [breakpoints.down('md')]: {
    minHeight: '100%',
    paddingTop: '4.375rem',
  },
}));

export const PageWidth = styled('div')(({ theme: { breakpoints } }) => ({
  width: '75rem',
  margin: '0 auto',

  [breakpoints.down('lg')]: {
    width: '100%',
    padding: '0 0.625rem',
  },

  [breakpoints.down('md')]: {
    // width: 'auto',
    // margin: '0 0.875rem'
  },
}));

export const MainContainer = styled(PageWidth)({
  flexGrow: '1',
  paddingBottom: '4.375rem',
});

export const OverflowHiddenContainer = styled(PageWidth)({
  overflow: 'hidden',
  width: '100%',
});

export type StyledOutboundLinkProps = OutboundLinkProps &
  HTMLProps<HTMLAnchorElement>;

export const StyledOutboundLink = styled((props: StyledOutboundLinkProps) => (
  // @ts-ignore
  <OutboundLink {...props} target='_blank' rel='noreferrer' />
))({});
