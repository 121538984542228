import React, {
  FC,
  ReactNode,
  createContext,
  useMemo,
  useContext,
  useEffect,
} from 'react';

import { TrackEvent } from '@/utils/analytics';

const AnalyticsContext = createContext<{
  tracker: TrackEvent;
} | null>(null);

export const AnalyticsProvider: FC<{
  children: ReactNode;
  location: Location;
}> = ({ children, location }) => {
  const tracker = useMemo(() => new TrackEvent(), []);

  useEffect(() => {
    if (location.pathname) {
      tracker.page({});
    }
  }, [tracker, location]);

  return (
    <AnalyticsContext.Provider value={{ tracker }}>
      {children}
    </AnalyticsContext.Provider>
  );
};

export function useTracker() {
  const context = useContext(AnalyticsContext);
  if (!context) {
    throw new Error('Missing Analytics context');
  }
  return context;
}
