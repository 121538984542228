import { FC, useEffect } from 'react';
import Cookies from 'js-cookie';

const CacheReferralCode: FC<{ location: Location }> = ({ location }) => {
  useEffect(() => {
    const referralCode = new URLSearchParams(location.search).get('ref');
    if (referralCode) {
      Cookies.set('ref_code', referralCode, {
        domain: location.hostname,
      });
    }
  }, [location]);

  return null;
};

export default CacheReferralCode;
