import { AGGREGATOR_BASE_URL } from '@/config/api';

export type Query = Record<string, string | string[]> | null;

export type Method = 'get' | 'post' | 'patch' | 'put' | 'delete';

export const DEFAULT_QUERY = {};

export function getQuery(url: string, query: Query) {
  const urlSearchParams = new URLSearchParams();
  for (const k in query) {
    const v = query[k];
    if (v instanceof Array) {
      v.forEach((o) => urlSearchParams.append(k, o));
    } else {
      urlSearchParams.set(k, v);
    }
  }
  return (url += '?' + urlSearchParams.toString());
}

export async function request(
  method: Method,
  url: string,
  body?: any,
  headers?: any
) {
  const opts: Record<string, any> = {};

  opts.headers = {
    ...headers,
  };

  if (method !== 'get') {
    opts.method = method;
    if (!('Content-Type' in opts.headers)) {
      opts.headers['Content-type'] = 'application/json';
    }
    if (body) opts.body = JSON.stringify(body);
  } else if (body) {
    url = getQuery(url, body);
  }
  try {
    return await fetch(url, opts);
  } catch (e) {
    console.error(e);
  }
}

export async function aggregator(
  method: Method,
  endpoint: string,
  body: any,
  headers?: any
) {
  return await request(method, AGGREGATOR_BASE_URL + endpoint, body, headers);
}
