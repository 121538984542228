import * as request from '@/utils/request';
import { AGGREGATOR_BASE_URL } from '@/config/api';
import { useRequest } from './useRequest';

export function useAggregatorRequest<T>(
  endpoint: string,
  query: request.Query,
  doPoll?: boolean
) {
  return useRequest<T>(AGGREGATOR_BASE_URL + endpoint, query, doPoll);
}
