exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-tsx": () => import("./../../../src/pages/about-us.tsx" /* webpackChunkName: "component---src-pages-about-us-tsx" */),
  "component---src-pages-about-us-yaml-locale-about-us-tsx": () => import("./../../../src/pages/{aboutUsYaml.locale}/about-us.tsx" /* webpackChunkName: "component---src-pages-about-us-yaml-locale-about-us-tsx" */),
  "component---src-pages-code-of-conduct-tsx": () => import("./../../../src/pages/code-of-conduct.tsx" /* webpackChunkName: "component---src-pages-code-of-conduct-tsx" */),
  "component---src-pages-code-of-conduct-yaml-locale-code-of-conduct-tsx": () => import("./../../../src/pages/{codeOfConductYaml.locale}/code-of-conduct.tsx" /* webpackChunkName: "component---src-pages-code-of-conduct-yaml-locale-code-of-conduct-tsx" */),
  "component---src-pages-four-o-four-yaml-locale-404-tsx": () => import("./../../../src/pages/{fourOFourYaml.locale}/404.tsx" /* webpackChunkName: "component---src-pages-four-o-four-yaml-locale-404-tsx" */),
  "component---src-pages-home-yaml-locale-index-tsx": () => import("./../../../src/pages/{homeYaml.locale}/index.tsx" /* webpackChunkName: "component---src-pages-home-yaml-locale-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-news-index-tsx": () => import("./../../../src/pages/news/index.tsx" /* webpackChunkName: "component---src-pages-news-index-tsx" */),
  "component---src-pages-news-leaderboard-is-back-tsx": () => import("./../../../src/pages/news/leaderboard-is-back.tsx" /* webpackChunkName: "component---src-pages-news-leaderboard-is-back-tsx" */),
  "component---src-pages-news-sot-v-0-9-8-tsx": () => import("./../../../src/pages/news/sot-v-0-9-8.tsx" /* webpackChunkName: "component---src-pages-news-sot-v-0-9-8-tsx" */),
  "component---src-pages-news-sot-v-0-9-9-tsx": () => import("./../../../src/pages/news/sot-v-0-9-9.tsx" /* webpackChunkName: "component---src-pages-news-sot-v-0-9-9-tsx" */),
  "component---src-pages-press-releases-tsx": () => import("./../../../src/pages/press-releases.tsx" /* webpackChunkName: "component---src-pages-press-releases-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-privacy-policy-yaml-locale-privacy-policy-tsx": () => import("./../../../src/pages/{privacyPolicyYaml.locale}/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-yaml-locale-privacy-policy-tsx" */),
  "component---src-pages-terms-of-use-tsx": () => import("./../../../src/pages/terms-of-use.tsx" /* webpackChunkName: "component---src-pages-terms-of-use-tsx" */),
  "component---src-pages-terms-of-use-yaml-locale-terms-of-use-tsx": () => import("./../../../src/pages/{termsOfUseYaml.locale}/terms-of-use.tsx" /* webpackChunkName: "component---src-pages-terms-of-use-yaml-locale-terms-of-use-tsx" */)
}

