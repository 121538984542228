import BigNumber from 'bignumber.js';

export const PRECISION = 1e9;

export const toBigNumber = (n: any) => new BigNumber(n);

export const formatNumber = (n: BigNumber, decimals: number = 2) =>
  n.isNaN() ? '0' : n.toFormat(decimals);

export const formatPreciseNumber = (n: BigNumber, decimals?: number) =>
  formatNumber(n.div(PRECISION), decimals);
