import React, { ReactNode } from 'react';
import { styled } from '@mui/material/styles';

export type ButtonVariant =
  | 'purple'
  | 'red'
  | 'green'
  | 'outline'
  | 'gray'
  | 'purple-green'
  | 'purple-yellow';

const flex = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '2.75rem',
};

export const button = {
  ...flex,
  fontFamily: 'Montserrat',
  fontStyle: 'normal',
  fontWeight: 'normal',
  fontSize: '0.875rem',
  lineHeight: '1rem',
  padding: '0.5rem 2.5rem',

  '&:disabled': {
    cursor: 'not-allowed',
    opacity: '0.5',
  },
};

const purpleGradientBg = `linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5))`;
const purpleGreenGradientBg = `linear-gradient(90deg, #cc2bdc 0%, #00e9c9 100%);`; // linear-gradient(270deg, #017465 0%, #65176f 100%)
const purpleYellowGradientBg = `linear-gradient(270deg, #ffda15 0%, #8b16ec 100%)`;

const purpleBg = 'rgba(159, 27, 188, 0.45)';
const purpleBorderColor = '#9F1BBC';

const redBg = '#7F0000';
const redBorderColor = '#B94E4E';

const greenBg =
  'linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), linear-gradient(270deg, #00E9C9 0%, #00FF57 100%)';
const greenBorderColor = '#00fd61';

const grayBg = '#777';
const grayBorderColor = '#888';

export const GradientBgContainer = styled(
  ({
    children,
    variant,
    ...props
  }: {
    children: ReactNode;
    className?: string;
    variant: ButtonVariant;
  }) => <div {...props}>{children}</div>
)(({ theme: { breakpoints }, variant }) => ({
  ...flex,

  ...(variant !== 'purple-green'
    ? null
    : {
        background: purpleGreenGradientBg,
      }),
  ...(variant !== 'purple-yellow'
    ? null
    : {
        background: purpleYellowGradientBg,
      }),
}));

export const GradientBgButton = styled(
  ({
    children,
    variant,
    ...props
  }: {
    children: ReactNode;
    variant: ButtonVariant;
  }) => <button {...props}>{children}</button>
)(({ theme: { breakpoints }, variant }) => ({
  ...button,

  margin: '0.125rem',

  ...(variant !== 'purple-green'
    ? null
    : {
        background: `${purpleGradientBg}, ${purpleGreenGradientBg}`,
        '&:hover': {
          opacity: 0.8,
        },
      }),
  ...(variant !== 'purple-yellow'
    ? null
    : {
        background: `${purpleGradientBg}, ${purpleYellowGradientBg}`,
        '&:hover': {
          opacity: 0.8,
        },
      }),
}));

export const Button = styled(
  ({
    children,
    variant,
    ...props
  }: {
    children: ReactNode;
    variant: ButtonVariant;
  }) => <button {...props}>{children}</button>
)(({ theme: { breakpoints }, variant }) => ({
  ...button,

  border: '0.0625rem solid',
  color: 'white',

  ...(variant !== 'outline'
    ? null
    : {
        background: 'transparent',
        borderColor: 'rgba(255, 255, 255, 0.5)',
        '&:hover': {
          color: 'black',
          background: 'white',
        },
      }),

  ...(variant !== 'purple'
    ? null
    : {
        background: purpleBg,
        borderColor: purpleBorderColor,
        '&:hover': {
          opacity: 0.8,
        },
      }),

  ...(variant !== 'red'
    ? null
    : {
        background: redBg,
        borderColor: redBorderColor,
        '&:hover': {
          opacity: 0.8,
        },
      }),

  ...(variant !== 'green'
    ? null
    : {
        background: greenBg,
        borderColor: greenBorderColor,
        '&:hover': {
          opacity: 0.8,
        },
      }),

  ...(variant !== 'gray'
    ? null
    : {
        background: grayBg,
        borderColor: grayBorderColor,
        '&:hover': {
          opacity: 0.8,
        },
      }),
}));
