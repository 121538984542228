import { useState, useMemo, useEffect } from 'react';

import * as request from '@/utils/request';
import { sleep, poll } from '@/utils/promise';

type Status = 'pending' | 'fetching' | 'done';

export function useRequest<T>(
  url: string | null,
  query: request.Query,
  doPoll?: boolean
) {
  const [result, setResult] = useState<T | null>(null);
  const [status, setStatus] = useState<Status>('pending');

  useEffect(() => {
    if (!(url && query)) return;

    let isMounted = true;
    const unsubs = [
      () => {
        isMounted = false;
      },
    ];

    const loadInfo = async () => {
      if (isMounted) {
        setStatus('fetching');
      }
      try {
        let fullUrl = url;
        if (query) {
          fullUrl = request.getQuery(url, query);
        }
        const res = await fetch(fullUrl);
        if (res.status !== 200) {
          throw new Error(await res.text());
        }
        const data = await res.json();
        if (isMounted) {
          setResult(data);
        }
      } catch (e) {
      } finally {
        if (isMounted) {
          setStatus('done');
        }
        await sleep(1);
        if (isMounted) {
          setStatus('pending');
        }
      }
    };

    loadInfo();

    if (doPoll) poll(unsubs, loadInfo);

    return () => unsubs.forEach((unsub) => unsub());
  }, [url, query, doPoll]);

  const working = useMemo(() => status === 'fetching', [status]);

  const ret = useMemo(
    () => ({
      result,
      status,
      working,
    }),
    [result, working, status]
  );

  return ret;
}
