import { StyledOutboundLink } from '@/styles/container';
import { styled } from '@mui/material/styles';
import { keyframes } from '@mui/styled-engine';

const TopBarMobileAnimation = keyframes`
  0% {transform: translate(0, 0)}
  25% {transform: translate(0, 0)}
  35% {transform: translate(0, -1.75rem)}
  50% {transform: translate(0, -3.5rem)}
  75% {transform: translate(0, -5.25rem)}
  100% {transform: translate(0, -5.25rem)}
`;

export const TopBarWrapper = styled('div')(({ theme: { breakpoints } }) => ({
  height: `1.5rem`,
  width: '100%',
  background: '#000',
  padding: '0.375rem 0',
  [breakpoints.down('md')]: {},
}));

export const ContentWrapper = styled('div')(({ theme: { breakpoints } }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '1rem',
  [breakpoints.down('md')]: {
    flexDirection: 'column',
    animation: `${TopBarMobileAnimation} 15s ease infinite`,
  },
}));

export const SingleContent = styled('div')(({ theme: { breakpoints } }) => ({
  fontSize: '0.625rem',
  lineHeight: '0.75rem',
  textTransform: 'uppercase',
}));

export const ContentBoldTitle = styled('span')(
  ({ theme: { breakpoints } }) => ({
    fontWeight: 500,
    marginRight: '0.5rem',
  })
);

export const DesktopSeperator = styled('div')(({ theme: { breakpoints } }) => ({
  width: '1px',
  height: '0.5rem',
  backgroundColor: 'white',
  [breakpoints.down('md')]: {
    display: 'none',
  },
}));
export const BuyAuryLink = styled(StyledOutboundLink)(
  ({ theme: { breakpoints } }) => ({
    color: '#00AF97',
  })
);
