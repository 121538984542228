import { useEffect } from 'react';
import Cookies from 'js-cookie';

export default function UTMTags() {
  useEffect(() => {
    const search = new URLSearchParams(window.location.search);
    for (const [key, value] of search.entries()) {
      if (key.startsWith('utm_')) {
        Cookies.set(key, value, {
          domain: 'aurory.io',
        });
      }
    }
  }, []);

  return null;
}
