import React from 'react';

import Header from './Header/Header';
import TopBar, { TopbarI18n } from './TopBar/TopBar';

import * as S from './HeaderTopbar.styled';

export type Menui18N = {
  playNow: string;
  marketplace: string;
  pressReleases: string;
  patchNotes: string;
  news: string;
  faq: string;
  aboutUs: string;
  gameDashboard: string;
  more: string;
  whitePaper: string;
};

export type HeaderI18N = {
  locale: string;
  topBar: TopbarI18n;
  menu: Menui18N;
};

const HeaderTopbar: React.FC<{
  i18n: HeaderI18N;
}> = ({ i18n }) => {
  return (
    <S.Container>
      <TopBar i18n={i18n.topBar} />
      <Header i18n={i18n.menu} />
    </S.Container>
  );
};

export default HeaderTopbar;
