import React, { FC, useMemo } from 'react';

import { useAggregatorRequest } from '@/hooks/useAggregatorRequest';
import {
  formatNumber,
  formatPreciseNumber,
  PRECISION,
  toBigNumber,
} from '@/utils/bn';
import * as request from '@/utils/request';

import * as S from './TopBar.styled';
import { SWAP } from '@/config/routes';

export type TopbarI18n = {
  marketCap: string;
};

interface ITopBarProps {
  i18n: TopbarI18n;
}

type TokenInfo = {
  circulating_supply: number;
  current_price: number;
  price_change_percentage_24h: number;
  total_supply: number;
};

type AurorianInfo = {
  floor_price: number;
};

const TopBar: FC<ITopBarProps> = ({ i18n }) => {
  const { result } = useAggregatorRequest<{
    aury: TokenInfo;
    sol: TokenInfo;
    aurorian: AurorianInfo;
  }>('/token-stats', request.DEFAULT_QUERY);

  const auryTokenStats = useMemo(() => result?.aury ?? null, [result]);
  const solanaTokenStats = useMemo(() => result?.sol ?? null, [result]);
  // const aurorianStats = useMemo(() => result?.aurorian ?? null, [result]);

  const circulatingAury = useMemo(
    () =>
      toBigNumber(
        !auryTokenStats ? '0' : auryTokenStats.circulating_supply ?? 0
      ).times(PRECISION),
    [auryTokenStats]
  );

  const auryUSDPrice = useMemo(
    () =>
      toBigNumber(!auryTokenStats ? '0' : auryTokenStats.current_price).times(
        PRECISION
      ),
    [auryTokenStats]
  );

  const solUSDPrice = useMemo(
    () =>
      toBigNumber(
        !solanaTokenStats ? '0' : solanaTokenStats.current_price
      ).times(PRECISION),
    [solanaTokenStats]
  );

  const auryMarketCap = useMemo(
    () => auryUSDPrice.div(PRECISION).times(circulatingAury).div(PRECISION),
    [auryUSDPrice, circulatingAury]
  );

  return (
    <S.TopBarWrapper>
      <S.ContentWrapper>
        <S.SingleContent>
          <S.ContentBoldTitle>{i18n.marketCap}</S.ContentBoldTitle> ${' '}
          {formatNumber(auryMarketCap)}
        </S.SingleContent>
        <S.DesktopSeperator />
        <S.SingleContent>
          <S.ContentBoldTitle>Sol/Usd</S.ContentBoldTitle> ${' '}
          {formatPreciseNumber(solUSDPrice)}
        </S.SingleContent>
        <S.DesktopSeperator />
        <S.SingleContent>
          <S.ContentBoldTitle>Aury/Usd</S.ContentBoldTitle> ${' '}
          {formatPreciseNumber(auryUSDPrice)}
        </S.SingleContent>
        <S.SingleContent>
          <S.BuyAuryLink href={SWAP}>{`${i18n.marketCap} >`}</S.BuyAuryLink>
        </S.SingleContent>
      </S.ContentWrapper>
    </S.TopBarWrapper>
  );
};

export default TopBar;
