import { useEffect } from 'react';

function useScrollToTop() {
  useEffect(() => {
    scrollToTop();
  }, []);

  return null;
}

export default useScrollToTop;

export function scrollToTop() {
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: 'smooth',
  });
}
