import React, { FC } from 'react';
import { Theme, styled } from '@mui/material/styles';
import Link from '@/components/shared/LocaleLink';

import DiscordImage from '@/images/social/discord.inline.svg';
import InstagramImage from '@/images/social/instagram.inline.svg';
// import MediumImage from '@/images/social/medium.inline.svg';
import SubstackImage from '@/images/social/substack.inline.svg';
import TelegramImage from '@/images/social/telegram.inline.svg';
import YoutubeImage from '@/images/social/youtube.inline.svg';
import TwitterImage from '@/images/social/twitter.inline.svg';
import logoBlanc from '@/images/logo-blanc.svg';
import scrollUpArrow from '@/images/scroll-up-arrow.svg';
import kittyFooterImage from '@/images/footer-kitty.png';
import { scrollToTop } from '@/hooks/useScrollToTop';
import * as routes from '@/config/routes';
import {
  StyledOutboundLink,
  StyledOutboundLinkProps,
} from '@/styles/container';

const YEAR = new Date().getFullYear();
export type Footeri18N = {
  whitepaper: string;
  support: string;
  codeOfConduct: string;
  pressReleases: string;
  aboutAurory: string;
  patchNotes: string;
  news: string;
  privacy: string;
  copyright: string;
  byAurory: string;
  termsOfService: string;
};

interface FooterProps {
  i18n: Footeri18N;
}
export const Footer: FC<FooterProps> = ({ i18n }) => {
  return (
    <Container>
      <KittyFooterImage src={kittyFooterImage} />
      <FooterLinksContainer>
        <FooterExternalLink href={routes.WHITEPAPER}>
          {i18n.whitepaper}
        </FooterExternalLink>
        <FooterExternalLink href={routes.SUPPORT}>
          {i18n.support}
        </FooterExternalLink>
        <FooterLink to={routes.ABOUT_US}> {i18n.aboutAurory}</FooterLink>
        <FooterLink href={routes.PATCH_NOTES}> {i18n.news}</FooterLink>
        <FooterLink to={routes.PRESS_RELEASES}>
          {' '}
          {i18n.pressReleases}
        </FooterLink>
        <FooterLink to={routes.CODE_OF_CONDUCT}>
          {' '}
          {i18n.codeOfConduct}
        </FooterLink>
      </FooterLinksContainer>
      <FooterSocialsContainer>
        <FooterSocials>
          <FooterSocialLink href={routes.DISCORD} org='discord'>
            <DiscordImage />
          </FooterSocialLink>
          <FooterSocialLink href={routes.TWITTTER} org='twitter'>
            <TwitterImage />
          </FooterSocialLink>
          {/* 
          <FooterSocialLink
            href='https://auroryproject.medium.com/'
            org='medium'
          >
            <MediumImage />
          </FooterSocialLink> 
          */}
          <FooterSocialLink href={routes.BLOG} org='substack'>
            <SubstackImage />
          </FooterSocialLink>
          <FooterSocialLink href={routes.TELEGRAM} org='telegram'>
            <TelegramImage />
          </FooterSocialLink>
          <FooterSocialLink href={routes.YOUTUBE} org='youtube'>
            <YoutubeImage />
          </FooterSocialLink>
          <FooterSocialLink href={routes.INSTAGRAM} org='instagram'>
            <InstagramImage />
          </FooterSocialLink>
        </FooterSocials>
        <LogoContainer>
          <LogoBlanc width={48} src={logoBlanc} />
        </LogoContainer>
        <CopyrightTextContainer>
          <CopyrightText>
            {i18n.copyright} {YEAR} {i18n.byAurory}
          </CopyrightText>
        </CopyrightTextContainer>
        <TermsLinksContainer>
          <TermsLink to={routes.PRIVACY_POLICY}> {i18n.privacy}</TermsLink>
          <TermsLink to={routes.TERMS_OF_USE}> {i18n.termsOfService}</TermsLink>
        </TermsLinksContainer>
        <FooterScrollUpArrowContainer onClick={() => scrollToTop()}>
          <FooterScrollUpArrow src={scrollUpArrow} />
        </FooterScrollUpArrowContainer>
      </FooterSocialsContainer>
    </Container>
  );
};

export default Footer;

const Container = styled('div')(({ theme: { breakpoints } }) => ({
  width: '100%',
  position: 'relative',
  [`${breakpoints.up('md')} and (orientation: portrait)`]: {},
  [`${breakpoints.up('md')} and (orientation: landscape)`]: {},
}));

const FooterLinksContainer = styled('div')(({ theme: { breakpoints } }) => ({
  width: '100%',
  display: 'flex',
  backgroundColor: '#343434',
  flexWrap: 'wrap',
  justifyContent: 'center',
  padding: '1.5rem 0',
  gap: '1rem',
  [`${breakpoints.up('md')}`]: {
    gap: '2rem',
    padding: '2.5rem 0',
  },
}));

const link = ({ theme: { breakpoints } }: { theme: Theme }) => ({
  fontSize: '1rem',
  fontWeight: 500,
  lineHeight: '1.1875rem',
  // position: 'relative',
  transition: 'all .2s ease',
  '&:after': {
    content: '""',
    position: 'absolute',
    bottom: '-5px',
    left: 0,
    width: '0',
    height: '2px',
    transition: 'all .2s ease',
    backgroundColor: '#fff',
  },
  '&:hover:after': {
    width: '100%',
  },
  [`${breakpoints.up('md')} and (orientation: portrait)`]: {},
  [`${breakpoints.up('md')} and (orientation: landscape)`]: {},
});

const FooterExternalLink = styled(StyledOutboundLink)(link);

const FooterLink = styled(Link)(link);

const FooterSocialsContainer = styled('div')(({ theme: { breakpoints } }) => ({
  backgroundColor: '#1A1A1A',
  width: '100%',
  height: 'auto',
  paddingTop: '3.5rem',
  [`${breakpoints.up('md')} and (orientation: portrait)`]: {},
  [`${breakpoints.up('md')} and (orientation: landscape)`]: {},
}));

const FooterSocials = styled('div')(({ theme: { breakpoints } }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '80%',
  maxWidth: '15.625rem',
  margin: '0 auto',
  paddingBottom: '3.5rem',
  rowGap: '2.5rem',
  columnGap: '1rem',
  flexWrap: 'wrap',
  [breakpoints.up('md')]: {
    maxWidth: 'auto',
    flexWrap: 'nowrap',
    rowGap: '1.5rem',
  },
}));

const FooterSocialLink = styled(
  ({ org, ...props }: StyledOutboundLinkProps & { org: string }) => (
    <StyledOutboundLink {...props} as='a' />
  )
)(({ org }) => ({
  color: '#343434',
  path: {
    position: 'absolute',
    top: '50%',
    left: '50%',
  },
  '&:hover': {
    ...(org === 'discord' ? { color: '#7289DA' } : {}),
    ...(org === 'twitter' ? { color: '#1DA1F2' } : {}),
    ...(org === 'medium' ? { color: '#000' } : {}),
    ...(org === 'substack' ? { color: '#ff6719' } : {}),
    ...(org === 'telegram' ? { color: '#0088CC' } : {}),
    ...(org === 'youtube' ? { color: '#FF0000' } : {}),
    ...(org === 'instagram' ? { color: '#E1306C' } : {}),
    ...(org === 'twitch' ? { color: '#9146FF' } : {}),
  },
}));

const LogoBlanc = styled('img')(({ theme: { breakpoints } }) => ({
  margin: '5rem auto 1.5rem auto',
  textAlign: 'center',
  [breakpoints.up('md')]: {
    margin: '2.5rem auto 1.5rem auto',
  },
  [`${breakpoints.up('md')} and (orientation: landscape)`]: {},
}));

const LogoContainer = styled('div')(({ theme: { breakpoints } }) => ({
  width: '100%',
  margin: '0 auto',
  textAlign: 'center',
  [`${breakpoints.up('md')} and (orientation: portrait)`]: {},
  [`${breakpoints.up('md')} and (orientation: landscape)`]: {},
}));

const CopyrightTextContainer = styled('div')(({ theme: { breakpoints } }) => ({
  margin: '0 auto 1.5rem auto',
  textAlign: 'center',
  maxWidth: '20.375rem',
  fontSize: '0.875rem',
  fontWeight: 300,
  [breakpoints.up('md')]: {
    maxWidth: '34rem',
  },
}));

const CopyrightText = styled('div')(({ theme: { breakpoints } }) => ({}));

const TermsLinksContainer = styled('div')(({ theme: { breakpoints } }) => ({
  display: 'flex',
  rowGap: '1.5rem',
  columnGap: '1rem',
  maxWidth: '20rem',
  flexWrap: 'wrap',
  margin: '0 auto',
  fontSize: '1rem',
  fontWeight: 600,
  textAlign: 'center',
  justifyContent: 'center',
  paddingBottom: '3.5rem',
  [`${breakpoints.up('md')}`]: {
    maxWidth: '32.875rem',
    columnGap: '2rem',
  },
}));

const TermsLink = styled(Link)(({ theme: { breakpoints } }) => ({}));

const FooterScrollUpArrowContainer = styled('div')(
  ({ theme: { breakpoints } }) => ({
    borderRadius: '100%',
    backgroundColor: '#E0E0E0',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '3.5rem',
    height: '3.5rem',
    marginLeft: 'auto',
    marginRight: '3rem',
    marginTop: '3rem',
    cursor: 'pointer',
    [`${breakpoints.up('md')}`]: {
      position: 'absolute',
      margin: '0',
      right: '2.9375rem',
      bottom: '2rem',
    },
  })
);

const FooterScrollUpArrow = styled('img')(({ theme: { breakpoints } }) => ({}));

const KittyFooterImage = styled('img')(({ theme: { breakpoints } }) => ({
  display: 'none',
  position: 'absolute',
  width: '21.8125rem',
  height: '29.3125rem',
  bottom: 0,
  [`${breakpoints.up('md')} and (orientation: landscape)`]: {
    display: 'block',
  },

  [breakpoints.up('lg')]: {
    width: '23.875rem',
    height: '33.25rem',
  },
  [breakpoints.up('xl')]: {
    width: '29.625rem',
    height: '39.8125rem',
  },
}));
