import React, { useState, useEffect, useCallback, HTMLProps } from 'react';
// import ReactAdroll from 'react-adroll';
import { styled } from '@mui/material';
import { Button } from './Button/Button';

interface ICookieConsentBannerProps {
  i18n: CookieConsenti18N;
}

export type CookieConsenti18N = {
  message: string;
  accept: string;
  decline: string;
};

export const CookieConsentBanner: React.FC<ICookieConsentBannerProps> = ({
  i18n,
}) => {
  const [cookieConseptBannerOpened, toggleCookieConsentBanner] =
    useState<boolean>(false);

  const onCookieConsentAccept = useCallback(() => {
    try {
      // ReactAdroll.init(
      //   '3UBDDT5L3FHOZDDRC3MYZB',
      //   'EEL7HKUUSVB6TA4P4CV5Z5',
      //   false
      // );
      toggleCookieConsentBanner(false);
      localStorage.setItem('cookie-accepted', 'true');
    } catch (err) {}
  }, []);

  useEffect(() => {
    const isCookieConsentAccepted = localStorage.getItem('cookie-accepted');
    if (isCookieConsentAccepted && isCookieConsentAccepted === 'true') {
      onCookieConsentAccept();
      toggleCookieConsentBanner(false);
    } else {
      toggleCookieConsentBanner(true);
    }
  }, [onCookieConsentAccept]);

  const onCookieConsentDecline = () => {
    toggleCookieConsentBanner(false);
  };

  return (
    <>
      {cookieConseptBannerOpened && (
        <Container>
          <Banner>
            <TextContainer>{i18n && i18n.message}</TextContainer>
            <ButtonContainer name='decline'>
              <Button variant='outline' onClick={onCookieConsentDecline}>
                {i18n && i18n.decline}
              </Button>
            </ButtonContainer>
            <ButtonContainer name='accept'>
              <Button variant='green' onClick={onCookieConsentAccept}>
                {i18n && i18n.accept}
              </Button>
            </ButtonContainer>
          </Banner>
        </Container>
      )}
    </>
  );
};

export const Container = styled('div')(({ theme }) => ({
  width: '100%',
  position: 'fixed',
  left: '0',
  right: '0',
  bottom: '0',
  zIndex: '50',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
}));

export const Banner = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'row',
  background: 'rgba(0, 0, 0, 0.9)',
  width: '100%',
  padding: '1rem',

  [theme.breakpoints.down('md')]: {
    display: 'grid',
    gridTemplateAreas: `
      "text text"
      "accept-button decline-button"
    `,
    gridTemplateColumns: '1fr 1fr',
    gridTemplateRows: '1fr 1fr',
    flexDirection: 'unset',
  },
}));

export const TextContainer = styled('div')(({ theme }) => ({
  fontFamily: "'Montserrat'",
  fontStyle: 'normal',
  fontWeight: '600',
  fontSize: '0.875rem',
  lineHeight: '1.063rem',
  color: '#ffffff',
  width: '60%',
  gridArea: 'text',

  [theme.breakpoints.down('md')]: {
    width: 'unset',
  },
}));

export const ButtonContainer = styled(
  ({ name, ...props }: HTMLProps<HTMLDivElement> & { name: string }) => (
    <div {...props} />
  )
)(({ theme, name }) => ({
  padding: '1rem',
  gridArea: `${name}-button`,
}));
