import React, { FC, ReactNode, useMemo, MouseEventHandler } from 'react';

import * as S from './Button.styled';

export type ButtonProps = {
  variant: S.ButtonVariant;
  children: ReactNode;
  className?: string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  'data-testid'?: string;
  disabled?: boolean;
};

export const Button: FC<ButtonProps> = ({
  variant,
  children,
  className: buttonClassName,
  onClick,
  'data-testid': dataTestId,
  disabled,
}) => {
  const className = `button ${buttonClassName || ''}`;

  const renderedContent = useMemo(() => {
    switch (variant) {
      case 'purple-green':
      case 'purple-yellow': {
        return (
          <S.GradientBgContainer {...{ variant }} className='button-container'>
            <S.GradientBgButton
              {...{ className, variant, onClick, disabled }}
              data-testid={dataTestId}
            >
              {children}
            </S.GradientBgButton>
          </S.GradientBgContainer>
        );
      }

      default: {
        return (
          <S.Button
            {...{ className, variant, onClick, disabled }}
            data-testid={dataTestId}
          >
            {children}
          </S.Button>
        );
      }
    }
  }, [variant, children, className, onClick, dataTestId, disabled]);

  return renderedContent;
};
