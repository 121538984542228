import { LOGS_URL } from '@/config/api';
import * as request from './request';

export interface PageEventData {
  page?: string;
  language?: string;
}

export interface ApiResponseEventData {
  endpoint: string;
  time: number;
}

export enum EventType {
  PAGE = 'page',
  PLAY_ADVENTURE = 'play_adventure',
}

export type AbstractEventData = Record<string, any>;

export class TrackEvent {
  constructor(private type: EventType = EventType.PAGE) {}

  public playAdventure() {
    this.type = EventType.PLAY_ADVENTURE;

    return this.track({});
  }

  public page(data: PageEventData) {
    this.type = EventType.PAGE;

    return this.track({
      ...data,
      routePath: window.location.pathname,
    });
  }

  public track(data: AbstractEventData) {
    this.trackGA({
      routePath: window.location.pathname,
    });

    return request.request('post', LOGS_URL, this.getEventJson(data));
  }

  public trackGA(data: AbstractEventData) {
    (window as any)?.gtag?.('event', this.type, data);
  }

  private getEventJson(data: AbstractEventData) {
    return {
      ...data,
      event: this.type,
    };
  }
}
