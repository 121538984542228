import React, {
  FC,
  ReactNode,
  createContext,
  useMemo,
  useContext,
  useState,
  useEffect,
  useCallback,
} from 'react';
import moment from 'moment';

import { useAggregatorRequest } from '@/hooks/useAggregatorRequest';

type Configuration = {
  metadata: {
    game: string;
    domain: string;
    config_version: string;
  };
  configuration: {
    name: string;
    type: string;
    value: {
      PRE_TOURNAMENT_START_NOTICE: Date;
      TOURNAMENT_START_TIME: Date;
      TOURNAMENT_END_TIME: Date;
      POST_TOURNAMENT_END_NOTICE_TIME: Date;
    };
  };
};
export type EventsData = {
  configurations: Configuration[];
};

const EventsContext = createContext<{
  eventIsOngoing: boolean;
  easterConfig: {
    etaToStart: number;
    etaToEnd: number;
    eventWillStart: boolean;
    eventOngoing: boolean;
    eventEnded: boolean;
  };
} | null>(null);

export const EventsProvider: FC<{ children: ReactNode }> = ({ children }) => {
  // const easterConfig = useEasterConfig();
  // const eventIsOngoing = useEventIsOngoing();
  const easterConfig = {
    etaToStart: 0,
    etaToEnd: 0,
    eventWillStart: false,
    eventOngoing: false,
    eventEnded: false,
  };
  const eventIsOngoing = false;

  return (
    <EventsContext.Provider value={{ eventIsOngoing, easterConfig }}>
      {children}
    </EventsContext.Provider>
  );
};

export const useEventIsOngoing = () => {
  const requestParams = useMemo(
    () => ({
      name: 'ALPHA_BATTLE_BASH_0', // todo: automate this
    }),
    []
  );
  const { result: eventsData } = useAggregatorRequest<EventsData>(
    `/configurations`,
    requestParams
  );

  const eventIsOngoing = useMemo(() => {
    const config = eventsData?.configurations?.[0].configuration.value;
    if (!config) return false;

    const now = moment.utc();
    const started = now.isAfter(moment.utc(config.TOURNAMENT_START_TIME));
    const finished = now.isAfter(moment.utc(config.TOURNAMENT_END_TIME));

    return started && !finished;
  }, [eventsData]);

  return eventIsOngoing;
};

export const useEasterConfig = () => {
  const startDate = useMemo(() => moment.utc('2023-04-10T17:00:00'), []);
  const endDate = useMemo(() => moment.utc('2023-04-17T17:00:00'), []);

  const compute = useCallback(
    () => () => {
      const now = moment.utc();
      return {
        etaToStart: startDate.diff(now, 'milliseconds') || 0,
        etaToEnd: endDate.diff(now, 'milliseconds') || 0,
      };
    },
    [startDate, endDate]
  );

  const [{ etaToStart, etaToEnd }, setEta] = useState(compute());

  useEffect(() => {
    const interval = setInterval(() => {
      setEta(compute());
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [compute]);

  return {
    etaToStart,
    etaToEnd,
    eventWillStart: etaToStart > 0,
    eventOngoing: etaToStart <= 0 && etaToEnd > 0,
    eventEnded: etaToEnd <= 0,
  };
};

export function useEvents() {
  const context = useContext(EventsContext);
  if (!context) {
    throw new Error('Missing Events context');
  }
  return context;
}
