import { createTheme } from '@mui/material';
import { BACKGROUND, PRIMARY } from '@/config/colors';

const theme = createTheme({
  typography: {
    fontFamily: ['Montserrat', 'sans-serif'].join(','),
  },
  palette: {
    background: {
      default: BACKGROUND,
    },
    text: {
      primary: PRIMARY,
    },
  },
});

export default theme;
